import { css } from '@emotion/css';
import { m, mobileMediaQuery, p2Css } from '@snapchat/snap-design-system-marketing';

export const jobListingHeaderContainerCss = css`
  align-self: stretch;
  display: flex;
  flex-wrap: nowrap;
  gap: ${m('--spacing-l')};
  margin-block-end: ${m('--spacing-m')};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${m('--spacing-xs')};
  }
`;

export const iconContainerCss = css`
  align-items: flex-start;
  display: flex;
  gap: ${m('--spacing-xs')};
  max-width: 25%;

  ${mobileMediaQuery} {
    gap: ${m('--spacing-s')};
    max-width: 100%;
  }
`;

export const iconCss = css`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${m('--spacing-l')};

  .sds-icon {
    fill: ${m('--fg-color')};
  }
`;

export const officesContainerCss = css`
  max-width: 100%;
`;

export const textIconCss = css`
  color: ${m('--fg-color')};
  max-width: 100%;
  overflow-wrap: break-word;
  ${p2Css}

  ${mobileMediaQuery} {
    line-height: ${m('--spacing-l')};
  }
`;
