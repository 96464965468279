import { cx } from '@emotion/css';
import { Anchor, BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { IntoPageBottomStickyPortal } from '../../../../components/Page/PageBottomStickyPortal';
import { Redirect } from '../../../../components/Redirect/Redirect';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { UserAction } from '../../../../types/events';
import { useJobPosting } from '../JobPosting/useJobPosting';
import {
  type JobMobileCallToActionQueryData,
  jobMobileCallToActionQuery,
} from './JobMobileCallToAction.query';
import {
  jobMobileCallToActionBlackBgCss,
  jobMobileCallToActionButtonBlackCss,
  jobMobileCallToActionButtonGrayCss,
  jobMobileCallToActionButtonStylesCss,
  jobMobileCallToActionButtonWhiteCss,
  jobMobileCallToActionButtonYellowCss,
  jobMobileCallToActionGrayBgCss,
  jobMobileCallToActionWhiteBgCss,
  jobMobileCallToActionWrapperCss,
  jobMobileCallToActionYellowBgCss,
} from './JobMobileCallToAction.styles';

// TODO: This component should use motifs instead of accepting a background color and button color
// https://jira.sc-corp.net/browse/WEBP-11039
export const JobMobileCallToAction: FC<{ id: string }> = props => {
  const { data: blockData } = useContentfulQuery<
    JobMobileCallToActionQueryData,
    ContentfulIdVariable
  >(jobMobileCallToActionQuery, {
    variables: { id: props.id },
  });

  const { data: jobData, isLoading } = useJobPosting();
  const { logEvent } = useContext(ConsumerContext);

  // Redirect to 404 page if Job data is not found
  if (!isLoading && !jobData?.id) {
    return <Redirect path={'/404'} />;
  }

  if (isLoading || !blockData || !jobData) return null;

  const { body, backgroundColor, buttonColor, analytics } = blockData.jobMobileCallToAction;
  const url = jobData.External_Apply_URL;

  return (
    <IntoPageBottomStickyPortal>
      <div
        className={cx(jobMobileCallToActionWrapperCss, {
          [jobMobileCallToActionYellowBgCss]: backgroundColor === BackgroundColor.Yellow,
          [jobMobileCallToActionWhiteBgCss]: backgroundColor === BackgroundColor.White,
          [jobMobileCallToActionGrayBgCss]: backgroundColor === BackgroundColor.Gray,
          [jobMobileCallToActionBlackBgCss]: backgroundColor === BackgroundColor.Black,
        })}
      >
        <Anchor
          className={cx(jobMobileCallToActionButtonStylesCss, {
            [jobMobileCallToActionButtonYellowCss]: buttonColor === BackgroundColor.Yellow,
            [jobMobileCallToActionButtonWhiteCss]: buttonColor === BackgroundColor.White,
            [jobMobileCallToActionButtonGrayCss]: buttonColor === BackgroundColor.Gray,
            [jobMobileCallToActionButtonBlackCss]: buttonColor === BackgroundColor.Black,
          })}
          href={url}
          onClick={() =>
            analytics && logEvent?.({ type: UserAction.Click, label: analytics.label })
          }
        >
          {body}
        </Anchor>
      </div>
    </IntoPageBottomStickyPortal>
  );
};
