import { css } from '@emotion/css';
import { m, mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const jobMobileCallToActionWrapperCss = css`
  border-top: 1px solid;
  display: none;

  ${mobileMediaQuery} {
    bottom: 0;
    display: block;
    padding: ${m('--spacing-xs')};
  }
`;

export const jobMobileCallToActionYellowBgCss = css`
  background-color: ${m('--palette-yellow-v100')};
  border-top-color: ${m('--palette-black-v50')};
`;

export const jobMobileCallToActionWhiteBgCss = css`
  background-color: ${m('--palette-plain-white')};
  border-top-color: ${m('--palette-gray-v250')};
`;

export const jobMobileCallToActionGrayBgCss = css`
  background-color: ${m('--palette-gray-v100')};
  border-top-color: ${m('--palette-gray-v250')};
`;

export const jobMobileCallToActionBlackBgCss = css`
  background-color: ${m('--palette-black-v200')};
  border-top-color: ${m('--palette-black-v50')};
`;

export const jobMobileCallToActionButtonStylesCss = css`
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  min-height: 54px;
  padding: ${m('--spacing-xs')} ${m('--spacing-m')};
  text-align: center;
  text-decoration: none;
`;

export const jobMobileCallToActionButtonYellowCss = css`
  background-color: ${m('--palette-yellow-v100')};
  color: ${m('--palette-black-v200')};
`;

export const jobMobileCallToActionButtonWhiteCss = css`
  background-color: ${m('--palette-plain-white')};
  color: ${m('--palette-black-v200')};
`;

export const jobMobileCallToActionButtonGrayCss = css`
  background-color: ${m('--palette-gray-v100')};
  color: ${m('--palette-black-v200')};
`;

export const jobMobileCallToActionButtonBlackCss = css`
  background-color: ${m('--palette-black-v200')};
  color: ${m('--palette-plain-white')};
`;
