import { css } from '@emotion/css';
import { m, mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

import { mediaQuery } from '../../../../styles/mediaQueries';
import { ScreenSize } from '../../../../styles/screenSizes';

export const boundaryCss = css`
  margin-left: auto;
  margin-right: auto;
  max-width: ${ScreenSize.width_1440_desktop_large}px;
  width: 100%;
  padding: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    padding: ${m('--spacing-m')};
  }
`;

export const filtersContainerCss = css`
  display: flex;

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const filterContainerCss = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  ${mediaQuery.over1024_desktop_medium} {
    flex-direction: row;
  }
`;

export const searchSectionCss = css`
  background-color: ${m('--palette-yellow-v100')};
`;

export const searchHeaderCss = css`
  width: 100%;
  text-align: center;
`;

export const dropdownMenuCss = css`
  align-items: center;
  display: flex;
  padding-block: ${m('--spacing-m')};
  margin-inline-end: ${m('--spacing-xl')};
`;

export const tableCss = css`
  table-layout: fixed;
  width: 100%;
`;

export const mobileJobContainerCss = css`
  & > * {
    border-bottom: solid 1px ${m('--palette-gray-v200')};
    margin-top: ${m('--spacing-m')};
  }
`;

export const tableHeaderCss = css`
  background-color: ${m('--palette-plain-white')};
  margin-top: ${m('--spacing-l')};
`;

export const emptyContainerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

export const spinnerContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: ${m('--spacing-m')};
`;
